import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import './assets/css/element-variables.scss'
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// import 'swiper/dist/css/swiper.css';
// Vue.use(VueAwesomeSwiper);
Vue.use(Element);
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')