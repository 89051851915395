import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: (resolve) => require(['@/pages/login'], resolve),
  },
  {
    path: '/speaker',
    component: (resolve) => require(['@/pages/Meeting/Speaker'], resolve),
  },
  {
    path: '/listener',
    component: (resolve) => require(['@/pages/Meeting/Listener'], resolve),
  },
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
