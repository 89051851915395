<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    /* if (!window.android) {
      let user_data = JSON.stringify({
        teacherId: "81c015678e754bb6b4a62c4a03a713e4",
        name: "T03",
      });
      let access_token = JSON.stringify({
        access_token:
          "eyJhbGciOiJIUzUxMiJ9.eyJzZXJ2ZXJfbmFtZSI6InpoanQtdGVhY2hlciIsInNjaG9vbF9pZCI6MiwidXNlcl9pZCI6IjgxYzAxNTY3OGU3NTRiYjZiNGE2MmM0YTAzYTcxM2U0IiwidXNlcl9rZXkiOiIzZTZmMDQ1Ni1mOTE3LTQzYWQtYjE5Zi0zNzkxYWMzY2RmNDYiLCJ1c2VybmFtZSI6IlQwMyJ9.LbHHc0iUM-wy2ip_QFthAKmoI8uQ8Tjff6ld2-sZ9UViwWQ5aGd1U-9E7ZVsg2OjHwKP4ijyOjS67BUI16ttSg",
        action: "submitLogin",
        expires_in: 43200,
      });
      sessionStorage.setItem("user_data", user_data);
      sessionStorage.setItem("access_token", access_token);
    } */
  },
  components: {},
};
</script>

<style>
#app {
  color: #000;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

* {
  touch-action: none;
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 18px;
}
</style>
